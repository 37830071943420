import { DatalensReportCategory } from './report-category.interface';
import { DatalensReportType } from './report-type.interface';

export const REPORTS_STATIC_CATEGORIES: DatalensReportCategory[] = [
	{
		id: '0',
		name: 'Все отчёты',
		code: 'all',
		static: true,
	},
	{
		id: '1',
		name: 'Избранное',
		code: 'favorites',
		static: true,
	},
];

export const REPORT_TYPE_ALL: DatalensReportType = {
	id: '0',
	name: 'Все типы',
	code: 'all',
};

export const DATALENS_APP_CODE = 'analytics';
export const DATALENS_REPORTS_MODULE_CODE = 'reports';
export const DATALENS_REPORTS_MODULE_ADMIN_CODE = 'admin-analytics';
